import { StatusNameEnum } from "common/enums/StatusEnum";
import { City } from "common/interfaces/City";
import { Status } from "common/interfaces/Status";
import { MainContext } from "common/interfaces/MainContext";
import { useCallback } from "react";
import { SearchBehaviorStatesType } from "../states/behaviors";
import { SearchEstateSearchConditionStatesType } from "../states/estateSearchConditions";
import { SearchMasterStatesType } from "../states/masters";

export const useSearchMasterMethods = (
  mainContext: MainContext,
  masterStates: SearchMasterStatesType,
  behaviorStates: SearchBehaviorStatesType,
  searchConditionStates: SearchEstateSearchConditionStatesType
) => {
  // 物件ステータスのデフォルト設定(公開・非公開)
  const setDefaultStatuses = useCallback(() => {
    if (masterStates.statuses.length > 0) {
      searchConditionStates.setEstateSearchConditions.statuses([
        { id: 1, name: StatusNameEnum.公開 } as Status,
        { id: 4, name: StatusNameEnum.非公開 } as Status
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterStates.statuses.length]);

  // 都道府県のデフォルト設定(会社マスタから)
  const setDefaultPrefectures = useCallback(
    (initDefaultPrefecturesRef: boolean) => {
      if (initDefaultPrefecturesRef) {
        if (masterStates.defaultPrefectures) {
          searchConditionStates.setEstateSearchConditions.prefs(
            masterStates.defaultPrefectures
          );

          if (masterStates.defaultPrefectures.length !== 0) {
            return true;
          }
        }
      }

      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [masterStates.defaultPrefectures]
  );

  // 市区町村のデフォルト設定(会社マスタから)
  const setDefaultCities = useCallback(
    (initDefaultCitiesRef: boolean) => {
      if (initDefaultCitiesRef) {
        if (masterStates.defaultCities) {
          searchConditionStates.setEstateSearchConditions.cities(
            masterStates.defaultCities as City[]
          );

          if (masterStates.defaultCities.length !== 0) {
            behaviorStates.setReadyToInitialSearch(true);
            return true;
          }
        }
      }

      return false;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [behaviorStates, masterStates.defaultCities]
  );

  return {
    setDefaultStatuses,
    setDefaultPrefectures,
    setDefaultCities
  } as const;
};

export type SearchMasterMethodsType = ReturnType<typeof useSearchMasterMethods>;
