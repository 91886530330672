import { StatusNameEnum } from "common/enums/StatusEnum";
import { MinimalEstate } from "common/queries/minimalEstates";

// 同一タイル成約済みまとめ
export const soldBlocksOfEstates = (
  estates: MinimalEstate[],
  soldEstate: MinimalEstate
): { [key: string]: MinimalEstate } => {
  const blocks = estates
    .map((x) => x)
    .filter(
      (child) =>
        child?.status === StatusNameEnum.成約済み &&
        child?.tileOnZoom16 === soldEstate.tileOnZoom16
    );

  return {
    [soldEstate.tileOnZoom16 || ""]: {
      ...soldEstate,
      id: soldEstate.tileOnZoom16,
      status: blocks.map((child) => child.status).includes(StatusNameEnum.公開)
        ? StatusNameEnum.公開
        : blocks.map((child) => child.status).includes(StatusNameEnum.非公開)
        ? StatusNameEnum.非公開
        : soldEstate.status,
      estateType: blocks.length === 1 ? blocks.at(0)?.estateType : "？",

      blocks: blocks
    }
  };
};
