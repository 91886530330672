import { Alert, Box, Button, Dialog, Grow, Typography } from "@mui/material";
import ButtonMapMenuClose from "assets/icons/btn_mapmenu_close.svg";
import MenuAreaSelect from "assets/icons/ic_menu_areaselect.svg";
import { PrefectureAutocomplete } from "components/Parts/Atoms/PrefectureAutocomplete";
import React, { useMemo } from "react";
import styles from "./SwitchAreaDialog.module.scss";
import { useSwitchAreaDialogHooks } from "./hooks";
import { SwitchAreaDialogProps } from "./interfaces";

const idPrefix = "switch-area-dialog";

export const SwitchAreaDialog = React.memo((props: SwitchAreaDialogProps) => {
  const {
    titRef,
    boxRef,
    listboxRef,

    selectedPrefectures,
    selectedCities,
    prefecturesErrorMessage,
    citiesErrorMessage,
    selectableCities,

    selectAllButtonOn,
    selectMyAreaButtonOn,
    okButtonOn,

    onPrefecturesChange,
    onCitiesClick,
    onResetButtonClick,
    onAllAreaButtonClick,
    onOkButtonClick,
    onClose,
    onBackPaperClick
  } = useSwitchAreaDialogHooks(props);

  const selectedCityCodes = useMemo(
    () => selectedCities.map((x) => x.city_code),
    [selectedCities]
  );

  const citiesSelector = useMemo(() => {
    const selectablePrefectures = Array.from(
      new Set(selectableCities.map((city) => city.pref_code))
    ).sort();

    return selectablePrefectures.map((prefCode) => {
      const prefName = selectableCities.find(
        (x) => x.pref_code === prefCode
      )?.pref_name;

      const areasOfPref = Array.from(
        new Set(
          selectableCities
            .filter((x) => x.pref_code === prefCode)
            .map((x) =>
              JSON.stringify({ area_code: x.area_code, area_name: x.area_name })
            )
        )
      )
        .map((x) => JSON.parse(x) as { area_code?: string; area_name?: string })
        .sort((a, b) => (a.area_code ?? "").localeCompare(b.area_code ?? ""));

      return areasOfPref.map((area, index) => {
        const citiesOfArea = selectableCities
          .filter(
            (x) => x.pref_code === prefCode && x.area_code === area.area_code
          )
          .sort((a, b) => a.city_code.localeCompare(b.city_code));

        return (
          <Box key={prefCode} className={styles.prefBox}>
            {index === 0 ? (
              <Box className={styles.prefName}>{prefName}</Box>
            ) : null}
            {area.area_code && (
              <Box className={styles.areaName}>{area.area_name}</Box>
            )}
            {citiesOfArea.map((city) => (
              <Box
                key={city.city_code}
                className={`${styles.btn_switch01} ${styles.click_act01} ${
                  selectedCityCodes.includes(city.city_code) ? styles.on : ""
                }`}
                onClick={() => onCitiesClick(city)}
              >
                {city.city_name}
              </Box>
            ))}
          </Box>
        );
      });
    });
  }, [selectableCities, onCitiesClick, selectedCityCodes]);

  return (
    <Dialog
      open={props.open}
      aria-labelledby={`${idPrefix}-title`}
      aria-describedby={`${idPrefix}-description`}
      onClose={props.onClose}
      disableEscapeKeyDown
      PaperProps={{ onClick: onBackPaperClick }}
      slotProps={{ backdrop: { className: styles.backdrop } }}
      classes={{
        paperScrollPaper: styles.paperScrollPaper,
        paper: styles.paper,
        container: styles.container
      }}
      TransitionComponent={Grow}
      transitionDuration={{ appear: 200, enter: 200, exit: 100 }}
    >
      <Box className={`${styles.popup_map} twbasic01 ${styles.on}`}>
        <Box className={styles.scroll}>
          <Box className={styles.tit} ref={titRef}>
            <Box component="span">
              <img src={MenuAreaSelect} alt="エリア選択" />
            </Box>
          </Box>
          <Box className={styles.btn_sub_close} onClick={props.onClose}>
            <img src={ButtonMapMenuClose} alt="閉じる" />
            <Typography component="b">閉じる</Typography>
          </Box>
          <Box className={styles.box} ref={boxRef}>
            <Box className={styles.box2}>
              <Box className={styles.ovfl}>
                <Box className={styles.popup_map_areaselect}>
                  {props.isAdministrator ? (
                    <Box className={styles.ctt}>
                      <Box className={styles.ken}>
                        <PrefectureAutocomplete
                          multiple={true}
                          value={selectedPrefectures}
                          disableCloseOnSelect
                          ListboxProps={{ ref: listboxRef }}
                          onChange={onPrefecturesChange}
                          setFatal={props.setFatal}
                          errorMessage={prefecturesErrorMessage || ""}
                        />
                      </Box>
                    </Box>
                  ) : null}
                  <Box className={styles.ctt}>
                    <Box className={styles.dtl}>
                      <Box className={styles.other}>
                        {props.isAdministrator ? (
                          <Box
                            className={`${styles.btn_pum02} ${
                              styles.click_act01
                            } ${selectAllButtonOn ? styles.on : styles.off}`}
                            onClick={onAllAreaButtonClick}
                          >
                            全て選択
                          </Box>
                        ) : null}
                        <Box
                          className={`${styles.btn_pum02} ${
                            styles.click_act01
                          } ${selectMyAreaButtonOn ? styles.on : styles.off} ${
                            styles.tantou
                          }`}
                          onClick={onResetButtonClick}
                        >
                          担当エリアのみ選択
                        </Box>
                      </Box>
                      <Box className={styles.area}>{citiesSelector}</Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={`${styles.popup_map_btn} ${styles.btn_2ko}`}>
              <Box className={styles.bottom}>
                {citiesErrorMessage ? (
                  <Alert className={styles.alert} color="error">
                    {citiesErrorMessage}
                  </Alert>
                ) : null}
                {props.forEstateList ? (
                  <>
                    <Button
                      variant="contained"
                      className={styles.cancelButton}
                      onClick={onClose}
                    >
                      キャンセル
                    </Button>
                    <Button
                      variant="contained"
                      className={styles.okButton}
                      disabled={!okButtonOn}
                      onClick={onOkButtonClick}
                    >
                      OK
                    </Button>
                  </>
                ) : (
                  <>
                    <Box
                      onClick={onClose}
                      className={`${styles.btn} ${styles.cancel}`}
                    >
                      閉じる
                    </Box>
                    <Box
                      onClick={onOkButtonClick}
                      className={`${styles.btn} ${styles.arrow} ${
                        okButtonOn ? styles.on : styles.off
                      }`}
                    >
                      切り替える
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
});
