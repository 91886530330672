import {
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@mui/material";
import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import {
  calcOld,
  dateToTerm,
  dateToTermString
} from "common/functions/estates";
import { MinimalEstate } from "common/queries/minimalEstates";
import React, { useCallback, useMemo } from "react";
import styles from "./ArchivePage.module.scss";

export const EstateList = React.memo(
  (props: {
    estates: MinimalEstate[];
    estateType: string | null | undefined;
    condominiumName: string | null | undefined;
  }) => {
    const groundList = useCallback(
      (estates: MinimalEstate[]) => (
        <TableContainer component={Paper} className={styles.container}>
          <Table className={styles.ground}>
            <TableHead>
              <TableRow>
                <TableCell>所在地</TableCell>
                <TableCell>価格</TableCell>
                <TableCell>広さ</TableCell>
                <TableCell>坪単価</TableCell>
                <TableCell> </TableCell>
                <TableCell>売出時期</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {estates?.map((x, index) => (
                <TableRow key={`row-${index}`}>
                  <TableCell>{x.fullAddress}</TableCell>
                  <TableCell>
                    {String(Math.round((x?.estatePrice || 0) / 100) * 100)} 万
                  </TableCell>
                  <TableCell>
                    {String((x?.tsuboArea || 0) - ((x?.tsuboArea || 0) % 1))} 坪
                  </TableCell>
                  <TableCell>{String(x.unitPrice)} 万/坪</TableCell>
                  <TableCell>
                    {x.currentStatus && (
                      <Chip
                        label={x.currentStatus}
                        className={styles.currentStatusLabel}
                      />
                    )}{" "}
                    {x.isLot === 1 && (
                      <Chip label="分譲地" className={styles.lotLabel} />
                    )}
                  </TableCell>
                  <TableCell>{dateToTermString(x.updatedAt)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ),
      []
    );

    const houseList = useCallback(
      (estates: MinimalEstate[]) => (
        <TableContainer component={Paper} className={styles.container}>
          <Table className={styles.house}>
            <TableHead>
              <TableRow>
                <TableCell>所在地</TableCell>
                <TableCell>価格</TableCell>
                <TableCell>広さ</TableCell>
                <TableCell> </TableCell>
                <TableCell>間取り</TableCell>
                <TableCell>売出時期</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {estates?.map((x, index) => (
                <TableRow key={`row-${index}`}>
                  <TableCell>{x.fullAddress}</TableCell>
                  <TableCell>
                    {String(Math.round((x?.estatePrice || 0) / 100) * 100)} 万
                  </TableCell>
                  <TableCell>
                    {String((x?.tsuboArea || 0) - ((x?.tsuboArea || 0) % 1))} 坪
                  </TableCell>
                  <TableCell> </TableCell>
                  <TableCell>{x.layout}</TableCell>
                  <TableCell>{dateToTermString(x.updatedAt)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ),
      []
    );

    const usedList = useCallback(
      (estates: MinimalEstate[]) => (
        <TableContainer component={Paper} className={styles.container}>
          <Table className={styles.used}>
            <TableHead>
              <TableRow>
                <TableCell>所在地</TableCell>
                <TableCell>価格</TableCell>
                <TableCell>広さ</TableCell>
                <TableCell>築年数</TableCell>
                <TableCell>間取り</TableCell>
                <TableCell>売出時期</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {estates?.map((x, index) => (
                <TableRow key={`row-${index}`}>
                  <TableCell>{x.fullAddress}</TableCell>
                  <TableCell>
                    {String(Math.round((x?.estatePrice || 0) / 100) * 100)} 万
                  </TableCell>
                  <TableCell>
                    {String((x?.tsuboArea || 0) - ((x?.tsuboArea || 0) % 1))} 坪
                  </TableCell>
                  <TableCell>{calcOld(x)} 年</TableCell>
                  <TableCell>{x.layout}</TableCell>
                  <TableCell>{dateToTermString(x.updatedAt)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ),
      []
    );

    const condominiumList = useCallback(
      (estates: MinimalEstate[]) => (
        <TableContainer component={Paper} className={styles.container}>
          <Table className={styles.condominium}>
            <TableHead>
              <TableRow>
                <TableCell>所在地</TableCell>
                <TableCell>価格</TableCell>
                <TableCell>所在階</TableCell>
                <TableCell>専有面積</TableCell>
                <TableCell>間取り</TableCell>
                <TableCell>売出時期</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {estates?.map((x, index) => (
                <TableRow key={`row-${index}`}>
                  <TableCell>{x.fullAddress}</TableCell>
                  <TableCell>
                    {String(Math.round((x?.estatePrice || 0) / 100) * 100)} 万
                  </TableCell>
                  <TableCell>{x.floor}階</TableCell>
                  <TableCell>
                    {String((x?.squareArea || 0) - ((x?.squareArea || 0) % 5))}
                    ㎡
                  </TableCell>
                  <TableCell>{x.layout}</TableCell>
                  <TableCell>{dateToTermString(x.updatedAt)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ),
      []
    );

    const businessList = useCallback(
      (estates: MinimalEstate[]) => (
        <TableContainer component={Paper} className={styles.container}>
          <Table className={styles.used}>
            <TableHead>
              <TableRow>
                <TableCell>所在地</TableCell>
                <TableCell>価格</TableCell>
                <TableCell>広さ</TableCell>
                <TableCell>築年数</TableCell>
                <TableCell>種目</TableCell>
                <TableCell>売出時期</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {estates?.map((x, index) => (
                <TableRow key={`row-${index}`}>
                  <TableCell>{x.fullAddress}</TableCell>
                  <TableCell>
                    {String(Math.round((x?.estatePrice || 0) / 100) * 100)} 万
                  </TableCell>
                  <TableCell>
                    {String((x?.tsuboArea || 0) - ((x?.tsuboArea || 0) % 1))} 坪
                  </TableCell>
                  <TableCell>{calcOld(x)} 年</TableCell>
                  <TableCell>{x.purposeB}</TableCell>
                  <TableCell>{dateToTermString(x.updatedAt)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ),
      []
    );

    const filteredEstates = useMemo(
      () => props.estates.filter((x) => x.estateType === props.estateType),
      [props.estateType, props.estates]
    );

    filteredEstates.sort((a, b) => {
      const order =
        ((dateToTerm(a.updatedAt) || 0) - (dateToTerm(b.updatedAt) || 0)) * -1;

      if (order === 0) {
        return (a.estatePrice || 0) - (b.estatePrice || 0);
      }

      return order;
    });

    return props.estateType === EstateTypeNameEnum.土地 ||
      props.estateType === EstateTypeNameEnum.分譲地
      ? groundList(filteredEstates)
      : props.estateType === EstateTypeNameEnum.新築建売
      ? houseList(filteredEstates)
      : props.estateType === EstateTypeNameEnum.中古戸建
      ? usedList(filteredEstates)
      : props.estateType === EstateTypeNameEnum.マンション
      ? condominiumList(filteredEstates)
      : props.estateType === EstateTypeNameEnum.事業用
      ? businessList(filteredEstates)
      : null;
  }
);
