import { Box, Link } from "@mui/material";
import logoYoko4c from "assets/icons/logo_yoko_4c.svg";
import { remainTrialDays } from "common/functions/utilities";
import React, { useMemo } from "react";
import styles from "./SumoraHeader.module.scss";
import { SumoraHeaderProps } from "./interfaces";

export const SumoraHeader = React.memo((props: SumoraHeaderProps) => {
  const { trial, trialEndAt } = props;

  const remainingDays = useMemo(() => {
    return remainTrialDays(trial, trialEndAt);
  }, [trial, trialEndAt]);

  return (
    <Box component="section" className={styles.menu_header}>
      <Box className={styles.left}>
        <Box
          component="figure"
          className={`${styles.logo} ${
            props.logoVisible ? styles.visible : styles.hidden
          }`}
        >
          <Link href="/">
            <img src={logoYoko4c} alt="sumora" />
          </Link>
        </Box>
        {props.logoVisible && remainingDays !== undefined ? (
          <Box className={styles.trial}>
            トライアル終了まで {remainingDays}日
          </Box>
        ) : null}
      </Box>
      <Box className={styles.right}>{props.children}</Box>
    </Box>
  );
});
