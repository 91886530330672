import { boundSearchableZoom } from "common/consts/estates";
import { EstateTypeNameEnum } from "common/enums/EstateTypeEnum";
import { StatusNameEnum } from "common/enums/StatusEnum";
import { getCitiesOfTiles } from "common/functions/geoJsons/citiesOfTiles";
import { incrementWithCycle } from "common/functions/utilities";
import { EstateFilterConditions } from "common/interfaces/EstateFilterConditions";
import { EstateSearchConditions } from "common/interfaces/EstateSearchConditions";
import { MinimalEstate } from "common/queries/minimalEstates";
import React, { useCallback } from "react";
import { SearchProps } from "../../interfaces";
import { SearchEstateMethodsType } from "../methods/estates";
import { SearchBehaviorStatesType } from "../states/behaviors";
import { SearchDialogStatesType } from "../states/dialogs";
import { SearchEstateSearchConditionStatesType } from "../states/estateSearchConditions";
import { SearchEstateStatesType } from "../states/estates";
import { SearchFilterConditionStatesType } from "../states/filterConditions";
import { SearchMapStatesType } from "../states/maps";
import { SearchMasterStatesType } from "../states/masters";

export const useSearchToolHandlers = (
  props: SearchProps,
  mapStates: SearchMapStatesType,
  masterStates: SearchMasterStatesType,
  searchConditionStates: SearchEstateSearchConditionStatesType,
  filterConditionStates: SearchFilterConditionStatesType,
  dialogStates: SearchDialogStatesType,
  estateStates: SearchEstateStatesType,
  searchMethods: SearchEstateMethodsType,
  behaviorStates: SearchBehaviorStatesType,
  setMenuOpen: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const onSwitchAreaClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      searchConditionStates.clearEstateSearchConditions.bound();
      searchConditionStates.clearEstateSearchConditions.tilesOnZoom12();
      searchConditionStates.clearEstateSearchConditions.tilesOnZoom14();
      searchConditionStates.clearEstateSearchConditions.tilesOnZoom16();

      dialogStates.setOpenSwitchAreaDialog(true);
      setMenuOpen(false);
    },
    [
      dialogStates,
      searchConditionStates.clearEstateSearchConditions,
      setMenuOpen
    ]
  );

  const onNewsClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {},
    []
  );

  const onBoundClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const newBound = !searchConditionStates.estateSearchConditions.bound;

      searchConditionStates.setEstateSearchConditions.bound(newBound);

      if (!newBound) {
        searchConditionStates.clearEstateSearchConditions.tilesOnZoom12();
        searchConditionStates.clearEstateSearchConditions.tilesOnZoom14();
        searchConditionStates.clearEstateSearchConditions.tilesOnZoom16();

        const conditions: EstateSearchConditions = {
          ...searchConditionStates.estateSearchConditions,
          tilesOnZoom12: [],
          tilesOnZoom14: [],
          tilesOnZoom16: []
        };

        searchMethods.searchEstate(
          props.searchMode,
          conditions,
          false,
          false,
          false
        );
      }
    },
    [
      props.searchMode,
      searchConditionStates.clearEstateSearchConditions,
      searchConditionStates.estateSearchConditions,
      searchConditionStates.setEstateSearchConditions,
      searchMethods
    ]
  );

  const onStatusModesChange = useCallback(
    (saleMode: boolean, closedMode: boolean, soldMode: boolean) => {
      let estateStatuses =
        searchConditionStates.estateSearchConditions.statuses;

      const searchSale =
        estateStatuses.find(
          (x) =>
            x.name === StatusNameEnum.公開 || x.name === StatusNameEnum.非公開
        ) === undefined && saleMode;

      const deleteSale =
        estateStatuses.find(
          (x) =>
            x.name === StatusNameEnum.公開 || x.name === StatusNameEnum.非公開
        ) !== undefined && !saleMode;

      const searchClosed =
        estateStatuses.find((x) => x.name === StatusNameEnum.成約済み) ===
          undefined &&
        (closedMode || soldMode);

      const deleteClosed =
        estateStatuses.find((x) => x.name === StatusNameEnum.成約済み) !==
          undefined && !(closedMode || soldMode);

      if (searchSale) {
        const estateStatus = masterStates.statuses.find(
          (x) => x.name === StatusNameEnum.公開
        );

        if (estateStatus) {
          estateStatuses.push(estateStatus);
          estateStatuses.push({ id: 4, name: "非公開" });
        }
      }

      if (searchClosed) {
        const estateStatus = masterStates.statuses.find(
          (x) => x.name === StatusNameEnum.成約済み
        );

        if (estateStatus) {
          estateStatuses.push(estateStatus);
        }
      }

      if (deleteSale || deleteClosed) {
        if (deleteSale) {
          estateStatuses = estateStatuses.filter(
            (x) =>
              x.name !== StatusNameEnum.公開 && x.name !== StatusNameEnum.非公開
          );
        }

        if (deleteClosed) {
          estateStatuses = estateStatuses.filter(
            (x) => x.name !== StatusNameEnum.成約済み
          );
        }

        const estates = [...(estateStates.estates || [])].filter((x) =>
          estateStatuses
            .map((estateStatus) => estateStatus.name)
            .includes(x.status || "")
        );

        estateStates.setEstates(estates as MinimalEstate[]);
        estateStates.setEstatesCount(estates.length);
      }

      const conditions: EstateSearchConditions = {
        ...searchConditionStates.estateSearchConditions,
        statuses: estateStatuses
      };

      searchConditionStates.setEstateSearchConditions.statuses(
        conditions.statuses
      );

      if (searchSale || searchClosed) {
        searchMethods.searchEstate(
          props.searchMode,
          conditions,
          false,
          true,
          false
        );
      }
    },
    [
      estateStates,
      masterStates.statuses,
      props.searchMode,
      searchConditionStates.estateSearchConditions,
      searchConditionStates.setEstateSearchConditions,
      searchMethods
    ]
  );

  const onSaleClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      behaviorStates.setSaleMode((x) => !x);
    },
    [behaviorStates]
  );

  const onClosedClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      behaviorStates.setClosedMode((x) => !x);
    },
    [behaviorStates]
  );

  const onSoldClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      behaviorStates.setClosedMode((x) => !x);
      behaviorStates.setSoldMode((x) => !x);
    },
    [behaviorStates]
  );

  const onEstateTypeButtonsClick = useCallback(
    (estateTypeName: string) => {
      let estateTypes = [...filterConditionStates.filterConditions.estateTypes];

      if (estateTypes.map((x) => x.name).includes(estateTypeName)) {
        estateTypes = estateTypes.filter((x) => x.name !== estateTypeName);
      } else {
        const estateType = masterStates.estateTypes.find(
          (x) => x.name === estateTypeName
        );

        if (estateType) {
          estateTypes.push(estateType);
        }
      }

      const conditions: EstateFilterConditions = {
        ...filterConditionStates.filterConditions,
        estateTypes: estateTypes
      };

      filterConditionStates.setFilterConditions.estateTypes(
        conditions.estateTypes
      );
    },
    [
      filterConditionStates.filterConditions,
      filterConditionStates.setFilterConditions,
      masterStates.estateTypes
    ]
  );

  const onGroundClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onEstateTypeButtonsClick(EstateTypeNameEnum.土地);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filterConditionStates.filterConditions,
      filterConditionStates.setFilterConditions,
      masterStates.estateTypes
    ]
  );

  const onBlockClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onEstateTypeButtonsClick(EstateTypeNameEnum.分譲地);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filterConditionStates.filterConditions,
      filterConditionStates.setFilterConditions,
      masterStates.estateTypes
    ]
  );

  const onHouseNewClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onEstateTypeButtonsClick(EstateTypeNameEnum.新築建売);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filterConditionStates.filterConditions,
      filterConditionStates.setFilterConditions,
      masterStates.estateTypes
    ]
  );

  const onHouseUsedClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onEstateTypeButtonsClick(EstateTypeNameEnum.中古戸建);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filterConditionStates.filterConditions,
      filterConditionStates.setFilterConditions,
      masterStates.estateTypes
    ]
  );

  const onMansionClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onEstateTypeButtonsClick(EstateTypeNameEnum.マンション);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filterConditionStates.filterConditions,
      filterConditionStates.setFilterConditions,
      masterStates.estateTypes
    ]
  );

  const onBusinessClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      onEstateTypeButtonsClick(EstateTypeNameEnum.事業用);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      filterConditionStates.filterConditions,
      filterConditionStates.setFilterConditions,
      masterStates.estateTypes
    ]
  );

  const onFilterClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      dialogStates.setShowEstateFilterDialog(true);
      dialogStates.setEstateFilterDialogSign(incrementWithCycle);
      setMenuOpen(false);
    },
    [dialogStates, setMenuOpen]
  );

  const onDrawingPriceClick: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (event) => {
        mapStates.setDspPrice((x) => !x);
      },
      [mapStates]
    );

  const onDrawingTsuboAreaClick: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (event) => {
        mapStates.setDspTsuboArea((x) => !x);
      },
      [mapStates]
    );

  const onDrawingESchoolClick: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (event) => {
        mapStates.setDspESchools((x) => !x);
      },
      [mapStates]
    );

  const onDrawingJSchoolClick: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (event) => {
        mapStates.setDspJSchools((x) => !x);
      },
      [mapStates]
    );

  const onDrawingESchool = useCallback(() => {
    if (
      (!searchConditionStates.estateSearchConditions.bound ||
        mapStates.zoom < boundSearchableZoom) &&
      searchConditionStates.estateSearchConditions.cities &&
      searchConditionStates.estateSearchConditions.cities.length !== 0
    ) {
      if (mapStates.dspESchools) {
        mapStates.setSelectedESchoolCities(
          searchConditionStates.estateSearchConditions.cities
        );
      } else {
        mapStates.setSelectedESchoolCities([]);
      }
    }
  }, [
    mapStates,
    searchConditionStates.estateSearchConditions.bound,
    searchConditionStates.estateSearchConditions.cities
  ]);

  const onDrawingJSchool = useCallback(() => {
    if (
      (!searchConditionStates.estateSearchConditions.bound ||
        mapStates.zoom < boundSearchableZoom) &&
      searchConditionStates.estateSearchConditions.cities &&
      searchConditionStates.estateSearchConditions.cities.length !== 0
    ) {
      if (mapStates.dspJSchools) {
        mapStates.setSelectedJSchoolCities(
          searchConditionStates.estateSearchConditions.cities
        );
      } else {
        mapStates.setSelectedJSchoolCities([]);
      }
    }
  }, [
    mapStates,
    searchConditionStates.estateSearchConditions.bound,
    searchConditionStates.estateSearchConditions.cities
  ]);

  const onBoundsChangeOnDrawingSchools = useCallback(() => {
    if (
      (mapStates.dspESchools || mapStates.dspJSchools) &&
      searchConditionStates.estateSearchConditions.bound &&
      mapStates.zoom >= boundSearchableZoom
    ) {
      const bounds = mapStates.map?.getBounds();
      const zoom = mapStates.zoom;

      if (bounds) {
        getCitiesOfTiles(bounds, zoom)
          .then((value) => {
            if (mapStates.dspESchools) {
              mapStates.setSelectedESchoolCities(value);
            } else {
              mapStates.setSelectedESchoolCities([]);
            }

            if (mapStates.dspJSchools) {
              mapStates.setSelectedJSchoolCities(value);
            } else {
              mapStates.setSelectedJSchoolCities([]);
            }
          })
          .catch(() => {
            mapStates.setSelectedESchoolCities([]);
            mapStates.setSelectedJSchoolCities([]);
          });
      }
    }
  }, [mapStates, searchConditionStates.estateSearchConditions.bound]);

  const onSelectDrawingSchoolClick: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (event) => {
        dialogStates.setShowSelectDrawingSchoolDialog(true);
        dialogStates.setSelectDrawingSchoolDialogSign(incrementWithCycle);
        setMenuOpen(false);
      },
      [dialogStates, setMenuOpen]
    );

  const onDrawingDoshasaigaiClick: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (event) => {
        mapStates.setDspDoshasaigai((x) => !x);
      },
      [mapStates]
    );

  const onDrawingKouzuiClick: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (event) => {
        mapStates.setDspKouzui((x) => !x);
      },
      [mapStates]
    );

  const onDrawingTsunamiClick: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (event) => {
        mapStates.setDspTsunami((x) => !x);
      },
      [mapStates]
    );

  const onDrawingTakashioClick: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (event) => {
        mapStates.setDspTakashio((x) => !x);
      },
      [mapStates]
    );

  const onDrawingYoutoClick: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (event) => {
        mapStates.setDspYouto((x) => !x);
      },
      [mapStates]
    );

  const onDrawingPoiClick: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (event) => {
        mapStates.setDspPoi((x) => !x);
      },
      [mapStates]
    );

  const onStickyNoteClick = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      behaviorStates.setStickyNoteMode((x) => !x);
      setMenuOpen(false);
    },
    [behaviorStates, setMenuOpen]
  );

  const onMeasureLengthClick: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (event) => {
        mapStates.setMeasureLengthMode(!mapStates.measureLengthMode);
        setMenuOpen(false);
      },
      [mapStates, setMenuOpen]
    );

  const onMeasureAreaClick: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (event) => {
        mapStates.setMeasureAreaMode((x) => !x);
        setMenuOpen(false);
      },
      [mapStates, setMenuOpen]
    );

  const onSearchRouteClick: React.MouseEventHandler<HTMLButtonElement> =
    useCallback(
      (event) => {
        mapStates.setSearchRouteMode((x) => !x);
        behaviorStates.setEditMode(false);
        setMenuOpen(false);
      },
      [behaviorStates, mapStates, setMenuOpen]
    );

  const onEditClick: React.MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      behaviorStates.setEditMode((x) => !x);
      mapStates.setSearchRouteMode(false);
      setMenuOpen(false);
    },
    [behaviorStates, mapStates, setMenuOpen]
  );

  return {
    onSwitchAreaClick,
    onNewsClick,
    onBoundClick,

    onStatusModesChange,

    onSaleClick,
    onClosedClick,
    onSoldClick,

    onGroundClick,
    onBlockClick,
    onHouseNewClick,
    onHouseUsedClick,
    onMansionClick,
    onBusinessClick,

    onFilterClick,
    onDrawingPriceClick,
    onDrawingTsuboAreaClick,
    onDrawingESchoolClick,
    onDrawingJSchoolClick,
    onDrawingESchool,
    onDrawingJSchool,
    onBoundsChangeOnDrawingSchools,
    onSelectDrawingSchoolClick,
    onDrawingDoshasaigaiClick,
    onDrawingKouzuiClick,
    onDrawingTsunamiClick,
    onDrawingTakashioClick,
    onDrawingYoutoClick,
    onDrawingPoiClick,
    onStickyNoteClick,

    onMeasureLengthClick,
    onMeasureAreaClick,
    onSearchRouteClick,
    onEditClick
  } as const;
};
