import { EditNote } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { User } from "API";
import logoTate4c from "assets/icons/logo_tate.svg";
import { memo } from "react";
import styles from "./Auth.module.scss";

export const TrialExpiredMessage = memo((props: { user: User | undefined }) => (
  <Box className={styles.trialExpired}>
    <Box className={styles.trialExpiredInner}>
      <Box className={styles.logo}>
        <img src={logoTate4c} alt="sumora" />
      </Box>
      <Typography variant="h2" className={styles.title}>
        トライアル終了のお知らせ
      </Typography>
      <Box className={styles.message}>
        {props.user?.name} 様<br />
        <br />
        「スモ〜ラ」トライアル版をご利用いただき、誠にありがとうございます。
        <br />
        トライアル期間が終了したことをご案内いたします。
        <br />
        <br />
        トライアル期間中にお試しいただいた機能やサービスが、ご期待に添えていることを願っております。
        <br />
        <br />
        引き続き「スモ〜ラ」をご利用いただくためにも、導入のご検討をお願いいたします。
        <br />
        <br />
        トライアルの結果をもとに、皆様のご意見やご感想をお伺いしたく存じます。
        <br />
        下記の“アンケートはこちら”をクリックしていただき、アンケートへのご協力をお願いいたします。
        <br />
        アンケートの回答にかかるお時間は約1分程度となります。
        <Box className={styles.button}>
          <Box
            component="a"
            href="https://docs.google.com/forms/d/e/1FAIpQLSeVd2DXCk9obeIL1idPKt9A3XxghbQ0MPplCNX03lmrO1jQgg/viewform?usp=sf_link"
            target="_blank"
          >
            <EditNote sx={{ marginRight: "0.3em" }} />
            アンケートはこちら
          </Box>
        </Box>
        今後とも「スモ〜ラ」をよろしくお願い申し上げます。
        <br />
      </Box>
    </Box>
  </Box>
));
