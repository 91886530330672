/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const incrementSearchAtomicCounterC = /* GraphQL */ `mutation IncrementSearchAtomicCounterC {
  incrementSearchAtomicCounterC {
    counterName
    sequence
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.IncrementSearchAtomicCounterCMutationVariables,
  APITypes.IncrementSearchAtomicCounterCMutation
>;
export const incrementEstateAtomicCounterC = /* GraphQL */ `mutation IncrementEstateAtomicCounterC($id: String!) {
  incrementEstateAtomicCounterC(id: $id) {
    counterName
    sequence
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.IncrementEstateAtomicCounterCMutationVariables,
  APITypes.IncrementEstateAtomicCounterCMutation
>;
export const incrementRecNoAtomicCounterC = /* GraphQL */ `mutation IncrementRecNoAtomicCounterC {
  incrementRecNoAtomicCounterC {
    counterName
    sequence
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.IncrementRecNoAtomicCounterCMutationVariables,
  APITypes.IncrementRecNoAtomicCounterCMutation
>;
export const createCognitoUserF = /* GraphQL */ `mutation CreateCognitoUserF(
  $group: String!
  $username: String!
  $companyId: String!
  $email: AWSEmail!
  $password: String!
  $name: String!
  $nameRuby: String!
  $tel: String
  $role: String
  $agreement: Boolean
  $trial: Boolean
  $trialEndAt: AWSDateTime
) {
  createCognitoUserF(
    group: $group
    username: $username
    companyId: $companyId
    email: $email
    password: $password
    name: $name
    nameRuby: $nameRuby
    tel: $tel
    role: $role
    agreement: $agreement
    trial: $trial
    trialEndAt: $trialEndAt
  ) {
    item {
      Username
      Attributes {
        Name
        Value
        __typename
      }
      UserCreateDate
      UserLastModifiedDate
      Enabled
      UserStatus
      MFAOptions {
        DeliveryMedium
        AttributeName
        __typename
      }
      Groups {
        GroupName
        UserPoolId
        Description
        RoleArn
        Precedence
        LastModifiedDate
        CreationDate
        __typename
      }
      User {
        username
        infoName
        name
        nameRuby
        tel
        role
        agreement
        trial
        trialEndAt
        companyId
        defaultCondition {
          estatePriceFrom
          estatePriceTo
          tsuboAreaFrom
          tsuboAreaTo
          completionYearFrom
          completionYearTo
          roomsFrom
          roomsTo
          bldConditionSelections
          petOk
          flat
          inMyCompany
          purposeSummaryBs
          alsoCreatedAtFrom
          alsoCreatedAtTo
          __typename
        }
        group
        email
        searchInfo
        owner
        createdAt
        updatedAt
        authenticatedAt
        companyName
        companyBranchName
        __typename
      }
      Company {
        id
        infoName
        startMark
        recNo
        name
        branchId
        branchName
        branchTel
        branchFax
        branchUrl
        branchAddress
        address
        tel
        fax
        url
        remarks
        license
        licenser
        licenseCount
        licenseNo
        guaranter
        job
        email
        logoImg
        latitude
        longitude
        areaPrefs
        areas
        areaNames
        eAreas
        eAreaNames
        estateTypes
        bldConditionSelections
        remarks2
        searchInfo
        owner
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCognitoUserFMutationVariables,
  APITypes.CreateCognitoUserFMutation
>;
export const updateCognitoUserF = /* GraphQL */ `mutation UpdateCognitoUserF(
  $group: String!
  $username: String!
  $companyId: String!
  $email: AWSEmail!
  $name: String!
  $nameRuby: String!
  $tel: String
  $role: String
  $agreement: Boolean
  $trial: Boolean
  $trialEndAt: AWSDateTime
) {
  updateCognitoUserF(
    group: $group
    username: $username
    companyId: $companyId
    email: $email
    name: $name
    nameRuby: $nameRuby
    tel: $tel
    role: $role
    agreement: $agreement
    trial: $trial
    trialEndAt: $trialEndAt
  ) {
    item {
      Username
      Attributes {
        Name
        Value
        __typename
      }
      UserCreateDate
      UserLastModifiedDate
      Enabled
      UserStatus
      MFAOptions {
        DeliveryMedium
        AttributeName
        __typename
      }
      Groups {
        GroupName
        UserPoolId
        Description
        RoleArn
        Precedence
        LastModifiedDate
        CreationDate
        __typename
      }
      User {
        username
        infoName
        name
        nameRuby
        tel
        role
        agreement
        trial
        trialEndAt
        companyId
        defaultCondition {
          estatePriceFrom
          estatePriceTo
          tsuboAreaFrom
          tsuboAreaTo
          completionYearFrom
          completionYearTo
          roomsFrom
          roomsTo
          bldConditionSelections
          petOk
          flat
          inMyCompany
          purposeSummaryBs
          alsoCreatedAtFrom
          alsoCreatedAtTo
          __typename
        }
        group
        email
        searchInfo
        owner
        createdAt
        updatedAt
        authenticatedAt
        companyName
        companyBranchName
        __typename
      }
      Company {
        id
        infoName
        startMark
        recNo
        name
        branchId
        branchName
        branchTel
        branchFax
        branchUrl
        branchAddress
        address
        tel
        fax
        url
        remarks
        license
        licenser
        licenseCount
        licenseNo
        guaranter
        job
        email
        logoImg
        latitude
        longitude
        areaPrefs
        areas
        areaNames
        eAreas
        eAreaNames
        estateTypes
        bldConditionSelections
        remarks2
        searchInfo
        owner
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCognitoUserFMutationVariables,
  APITypes.UpdateCognitoUserFMutation
>;
export const deleteCognitoUserF = /* GraphQL */ `mutation DeleteCognitoUserF($username: String!) {
  deleteCognitoUserF(username: $username) {
    item {
      Username
      Attributes {
        Name
        Value
        __typename
      }
      UserCreateDate
      UserLastModifiedDate
      Enabled
      UserStatus
      MFAOptions {
        DeliveryMedium
        AttributeName
        __typename
      }
      Groups {
        GroupName
        UserPoolId
        Description
        RoleArn
        Precedence
        LastModifiedDate
        CreationDate
        __typename
      }
      User {
        username
        infoName
        name
        nameRuby
        tel
        role
        agreement
        trial
        trialEndAt
        companyId
        defaultCondition {
          estatePriceFrom
          estatePriceTo
          tsuboAreaFrom
          tsuboAreaTo
          completionYearFrom
          completionYearTo
          roomsFrom
          roomsTo
          bldConditionSelections
          petOk
          flat
          inMyCompany
          purposeSummaryBs
          alsoCreatedAtFrom
          alsoCreatedAtTo
          __typename
        }
        group
        email
        searchInfo
        owner
        createdAt
        updatedAt
        authenticatedAt
        companyName
        companyBranchName
        __typename
      }
      Company {
        id
        infoName
        startMark
        recNo
        name
        branchId
        branchName
        branchTel
        branchFax
        branchUrl
        branchAddress
        address
        tel
        fax
        url
        remarks
        license
        licenser
        licenseCount
        licenseNo
        guaranter
        job
        email
        logoImg
        latitude
        longitude
        areaPrefs
        areas
        areaNames
        eAreas
        eAreaNames
        estateTypes
        bldConditionSelections
        remarks2
        searchInfo
        owner
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCognitoUserFMutationVariables,
  APITypes.DeleteCognitoUserFMutation
>;
export const agreeCognitoUserF = /* GraphQL */ `mutation AgreeCognitoUserF($username: String!, $agreement: Boolean) {
  agreeCognitoUserF(username: $username, agreement: $agreement) {
    item {
      Username
      Attributes {
        Name
        Value
        __typename
      }
      UserCreateDate
      UserLastModifiedDate
      Enabled
      UserStatus
      MFAOptions {
        DeliveryMedium
        AttributeName
        __typename
      }
      Groups {
        GroupName
        UserPoolId
        Description
        RoleArn
        Precedence
        LastModifiedDate
        CreationDate
        __typename
      }
      User {
        username
        infoName
        name
        nameRuby
        tel
        role
        agreement
        trial
        trialEndAt
        companyId
        defaultCondition {
          estatePriceFrom
          estatePriceTo
          tsuboAreaFrom
          tsuboAreaTo
          completionYearFrom
          completionYearTo
          roomsFrom
          roomsTo
          bldConditionSelections
          petOk
          flat
          inMyCompany
          purposeSummaryBs
          alsoCreatedAtFrom
          alsoCreatedAtTo
          __typename
        }
        group
        email
        searchInfo
        owner
        createdAt
        updatedAt
        authenticatedAt
        companyName
        companyBranchName
        __typename
      }
      Company {
        id
        infoName
        startMark
        recNo
        name
        branchId
        branchName
        branchTel
        branchFax
        branchUrl
        branchAddress
        address
        tel
        fax
        url
        remarks
        license
        licenser
        licenseCount
        licenseNo
        guaranter
        job
        email
        logoImg
        latitude
        longitude
        areaPrefs
        areas
        areaNames
        eAreas
        eAreaNames
        estateTypes
        bldConditionSelections
        remarks2
        searchInfo
        owner
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.AgreeCognitoUserFMutationVariables,
  APITypes.AgreeCognitoUserFMutation
>;
export const saveDefaultConditionOfUserF = /* GraphQL */ `mutation SaveDefaultConditionOfUserF(
  $username: String!
  $defaultCondition: DefaultConditionInput!
) {
  saveDefaultConditionOfUserF(
    username: $username
    defaultCondition: $defaultCondition
  ) {
    item {
      Username
      Attributes {
        Name
        Value
        __typename
      }
      UserCreateDate
      UserLastModifiedDate
      Enabled
      UserStatus
      MFAOptions {
        DeliveryMedium
        AttributeName
        __typename
      }
      Groups {
        GroupName
        UserPoolId
        Description
        RoleArn
        Precedence
        LastModifiedDate
        CreationDate
        __typename
      }
      User {
        username
        infoName
        name
        nameRuby
        tel
        role
        agreement
        trial
        trialEndAt
        companyId
        defaultCondition {
          estatePriceFrom
          estatePriceTo
          tsuboAreaFrom
          tsuboAreaTo
          completionYearFrom
          completionYearTo
          roomsFrom
          roomsTo
          bldConditionSelections
          petOk
          flat
          inMyCompany
          purposeSummaryBs
          alsoCreatedAtFrom
          alsoCreatedAtTo
          __typename
        }
        group
        email
        searchInfo
        owner
        createdAt
        updatedAt
        authenticatedAt
        companyName
        companyBranchName
        __typename
      }
      Company {
        id
        infoName
        startMark
        recNo
        name
        branchId
        branchName
        branchTel
        branchFax
        branchUrl
        branchAddress
        address
        tel
        fax
        url
        remarks
        license
        licenser
        licenseCount
        licenseNo
        guaranter
        job
        email
        logoImg
        latitude
        longitude
        areaPrefs
        areas
        areaNames
        eAreas
        eAreaNames
        estateTypes
        bldConditionSelections
        remarks2
        searchInfo
        owner
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SaveDefaultConditionOfUserFMutationVariables,
  APITypes.SaveDefaultConditionOfUserFMutation
>;
export const resetTemporaryPasswordOfCognitoUserF = /* GraphQL */ `mutation ResetTemporaryPasswordOfCognitoUserF($username: String!) {
  resetTemporaryPasswordOfCognitoUserF(username: $username) {
    item {
      Username
      Attributes {
        Name
        Value
        __typename
      }
      UserCreateDate
      UserLastModifiedDate
      Enabled
      UserStatus
      MFAOptions {
        DeliveryMedium
        AttributeName
        __typename
      }
      Groups {
        GroupName
        UserPoolId
        Description
        RoleArn
        Precedence
        LastModifiedDate
        CreationDate
        __typename
      }
      User {
        username
        infoName
        name
        nameRuby
        tel
        role
        agreement
        trial
        trialEndAt
        companyId
        defaultCondition {
          estatePriceFrom
          estatePriceTo
          tsuboAreaFrom
          tsuboAreaTo
          completionYearFrom
          completionYearTo
          roomsFrom
          roomsTo
          bldConditionSelections
          petOk
          flat
          inMyCompany
          purposeSummaryBs
          alsoCreatedAtFrom
          alsoCreatedAtTo
          __typename
        }
        group
        email
        searchInfo
        owner
        createdAt
        updatedAt
        authenticatedAt
        companyName
        companyBranchName
        __typename
      }
      Company {
        id
        infoName
        startMark
        recNo
        name
        branchId
        branchName
        branchTel
        branchFax
        branchUrl
        branchAddress
        address
        tel
        fax
        url
        remarks
        license
        licenser
        licenseCount
        licenseNo
        guaranter
        job
        email
        logoImg
        latitude
        longitude
        areaPrefs
        areas
        areaNames
        eAreas
        eAreaNames
        estateTypes
        bldConditionSelections
        remarks2
        searchInfo
        owner
        createdAt
        updatedAt
        __typename
      }
      __typename
    }
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ResetTemporaryPasswordOfCognitoUserFMutationVariables,
  APITypes.ResetTemporaryPasswordOfCognitoUserFMutation
>;
export const updateCompanyF = /* GraphQL */ `mutation UpdateCompanyF($input: UpdateCompanyInput!) {
  updateCompanyF(input: $input) {
    item {
      id
      infoName
      startMark
      recNo
      name
      branchId
      branchName
      branchTel
      branchFax
      branchUrl
      branchAddress
      address
      tel
      fax
      url
      remarks
      license
      licenser
      licenseCount
      licenseNo
      guaranter
      job
      email
      logoImg
      latitude
      longitude
      areaPrefs
      areas
      areaNames
      eAreas
      eAreaNames
      estateTypes
      bldConditionSelections
      remarks2
      searchInfo
      owner
      createdAt
      updatedAt
      __typename
    }
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCompanyFMutationVariables,
  APITypes.UpdateCompanyFMutation
>;
export const deleteCompanyF = /* GraphQL */ `mutation DeleteCompanyF($input: DeleteCompanyInput!) {
  deleteCompanyF(input: $input) {
    item {
      id
      infoName
      startMark
      recNo
      name
      branchId
      branchName
      branchTel
      branchFax
      branchUrl
      branchAddress
      address
      tel
      fax
      url
      remarks
      license
      licenser
      licenseCount
      licenseNo
      guaranter
      job
      email
      logoImg
      latitude
      longitude
      areaPrefs
      areas
      areaNames
      eAreas
      eAreaNames
      estateTypes
      bldConditionSelections
      remarks2
      searchInfo
      owner
      createdAt
      updatedAt
      __typename
    }
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCompanyFMutationVariables,
  APITypes.DeleteCompanyFMutation
>;
export const createEstateF = /* GraphQL */ `mutation CreateEstateF($input: CreateEstateInput!) {
  createEstateF(input: $input) {
    item {
      id
      infoName
      condominiumId
      confirmed
      status
      estateType
      name
      fullAddress
      prefecture
      city
      area
      address
      mediationKind
      currentStatus
      currentStatusFree
      traffic
      eSchool
      jSchool
      remarks
      landAreaType
      squareArea
      tsuboArea
      estatePrice
      unitPrice
      pricedAt
      priceHistory {
        price
        pricedAt
        __typename
      }
      completionSchMonth
      completionMonth
      clearingType
      cleaningMonth
      bldConditionType
      landRights
      cityPlanningType
      useDistrict
      floorAreaRatio
      bcr
      roadType
      drivewayPay
      roadDirection
      roadWidth
      water
      sewer
      gas
      groundType
      groundTypeFree
      layout
      totalArea
      structure
      structureFree
      buildingFloors
      balconyArea
      managementType
      pet
      floor
      room
      managementFee
      repairCost
      otherCost
      parking
      carsInParkingN
      parkingTakeOver
      parkingTakeOverFree
      parkingMechOrPlane
      parkingHeight
      parkingFee
      purposeB
      purposeSummaryB
      companyId
      companyName
      companyAddress
      companyTel
      companyFax
      companyUrl
      companyLicense
      companyGuaranter
      subCompanyId
      subCompanyName
      subCompanyAddress
      subCompanyTel
      subCompanyFax
      subCompanyUrl
      subCompanyLicense
      subCompanyGuaranter
      subCompany2Id
      subCompany2Name
      subCompany2Address
      subCompany2Tel
      subCompany2Fax
      subCompany2Url
      subCompany2License
      subCompany2Guaranter
      latitude
      longitude
      tileOnZoom12
      tileOnZoom14
      tileOnZoom16
      layoutImgs
      appearanceImgs
      mapImg
      waterPdf
      sewerPdf
      gasPdf
      cadastralMapPdf
      groundPdf
      url
      license
      recNo
      media
      location
      collectedAt
      floorStructure
      buildingCompany
      notFoundAt
      exactLocation
      nearlyLocation
      unsettledLocation
      isLot
      currentStatusB
      currentStatusG
      companyRecNo
      companyBranchId
      subCompanyRecNo
      completionMonthS
      condominiumName
      condominiumRecNo
      totalHouses
      manager
      seller
      estateUrl
      searchInfo
      owner
      createdAt
      updatedAt
      sold
      __typename
    }
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEstateFMutationVariables,
  APITypes.CreateEstateFMutation
>;
export const updateEstateF = /* GraphQL */ `mutation UpdateEstateF($input: UpdateEstateInput!) {
  updateEstateF(input: $input) {
    item {
      id
      infoName
      condominiumId
      confirmed
      status
      estateType
      name
      fullAddress
      prefecture
      city
      area
      address
      mediationKind
      currentStatus
      currentStatusFree
      traffic
      eSchool
      jSchool
      remarks
      landAreaType
      squareArea
      tsuboArea
      estatePrice
      unitPrice
      pricedAt
      priceHistory {
        price
        pricedAt
        __typename
      }
      completionSchMonth
      completionMonth
      clearingType
      cleaningMonth
      bldConditionType
      landRights
      cityPlanningType
      useDistrict
      floorAreaRatio
      bcr
      roadType
      drivewayPay
      roadDirection
      roadWidth
      water
      sewer
      gas
      groundType
      groundTypeFree
      layout
      totalArea
      structure
      structureFree
      buildingFloors
      balconyArea
      managementType
      pet
      floor
      room
      managementFee
      repairCost
      otherCost
      parking
      carsInParkingN
      parkingTakeOver
      parkingTakeOverFree
      parkingMechOrPlane
      parkingHeight
      parkingFee
      purposeB
      purposeSummaryB
      companyId
      companyName
      companyAddress
      companyTel
      companyFax
      companyUrl
      companyLicense
      companyGuaranter
      subCompanyId
      subCompanyName
      subCompanyAddress
      subCompanyTel
      subCompanyFax
      subCompanyUrl
      subCompanyLicense
      subCompanyGuaranter
      subCompany2Id
      subCompany2Name
      subCompany2Address
      subCompany2Tel
      subCompany2Fax
      subCompany2Url
      subCompany2License
      subCompany2Guaranter
      latitude
      longitude
      tileOnZoom12
      tileOnZoom14
      tileOnZoom16
      layoutImgs
      appearanceImgs
      mapImg
      waterPdf
      sewerPdf
      gasPdf
      cadastralMapPdf
      groundPdf
      url
      license
      recNo
      media
      location
      collectedAt
      floorStructure
      buildingCompany
      notFoundAt
      exactLocation
      nearlyLocation
      unsettledLocation
      isLot
      currentStatusB
      currentStatusG
      companyRecNo
      companyBranchId
      subCompanyRecNo
      completionMonthS
      condominiumName
      condominiumRecNo
      totalHouses
      manager
      seller
      estateUrl
      searchInfo
      owner
      createdAt
      updatedAt
      sold
      __typename
    }
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEstateFMutationVariables,
  APITypes.UpdateEstateFMutation
>;
export const deleteEstateF = /* GraphQL */ `mutation DeleteEstateF($input: DeleteEstateInput!) {
  deleteEstateF(input: $input) {
    item {
      id
      infoName
      condominiumId
      confirmed
      status
      estateType
      name
      fullAddress
      prefecture
      city
      area
      address
      mediationKind
      currentStatus
      currentStatusFree
      traffic
      eSchool
      jSchool
      remarks
      landAreaType
      squareArea
      tsuboArea
      estatePrice
      unitPrice
      pricedAt
      priceHistory {
        price
        pricedAt
        __typename
      }
      completionSchMonth
      completionMonth
      clearingType
      cleaningMonth
      bldConditionType
      landRights
      cityPlanningType
      useDistrict
      floorAreaRatio
      bcr
      roadType
      drivewayPay
      roadDirection
      roadWidth
      water
      sewer
      gas
      groundType
      groundTypeFree
      layout
      totalArea
      structure
      structureFree
      buildingFloors
      balconyArea
      managementType
      pet
      floor
      room
      managementFee
      repairCost
      otherCost
      parking
      carsInParkingN
      parkingTakeOver
      parkingTakeOverFree
      parkingMechOrPlane
      parkingHeight
      parkingFee
      purposeB
      purposeSummaryB
      companyId
      companyName
      companyAddress
      companyTel
      companyFax
      companyUrl
      companyLicense
      companyGuaranter
      subCompanyId
      subCompanyName
      subCompanyAddress
      subCompanyTel
      subCompanyFax
      subCompanyUrl
      subCompanyLicense
      subCompanyGuaranter
      subCompany2Id
      subCompany2Name
      subCompany2Address
      subCompany2Tel
      subCompany2Fax
      subCompany2Url
      subCompany2License
      subCompany2Guaranter
      latitude
      longitude
      tileOnZoom12
      tileOnZoom14
      tileOnZoom16
      layoutImgs
      appearanceImgs
      mapImg
      waterPdf
      sewerPdf
      gasPdf
      cadastralMapPdf
      groundPdf
      url
      license
      recNo
      media
      location
      collectedAt
      floorStructure
      buildingCompany
      notFoundAt
      exactLocation
      nearlyLocation
      unsettledLocation
      isLot
      currentStatusB
      currentStatusG
      companyRecNo
      companyBranchId
      subCompanyRecNo
      completionMonthS
      condominiumName
      condominiumRecNo
      totalHouses
      manager
      seller
      estateUrl
      searchInfo
      owner
      createdAt
      updatedAt
      sold
      __typename
    }
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEstateFMutationVariables,
  APITypes.DeleteEstateFMutation
>;
export const convertEstateGroundToLotF = /* GraphQL */ `mutation ConvertEstateGroundToLotF($input: DeleteEstateInput!) {
  convertEstateGroundToLotF(input: $input) {
    item {
      id
      infoName
      condominiumId
      confirmed
      status
      estateType
      name
      fullAddress
      prefecture
      city
      area
      address
      mediationKind
      currentStatus
      currentStatusFree
      traffic
      eSchool
      jSchool
      remarks
      landAreaType
      squareArea
      tsuboArea
      estatePrice
      unitPrice
      pricedAt
      priceHistory {
        price
        pricedAt
        __typename
      }
      completionSchMonth
      completionMonth
      clearingType
      cleaningMonth
      bldConditionType
      landRights
      cityPlanningType
      useDistrict
      floorAreaRatio
      bcr
      roadType
      drivewayPay
      roadDirection
      roadWidth
      water
      sewer
      gas
      groundType
      groundTypeFree
      layout
      totalArea
      structure
      structureFree
      buildingFloors
      balconyArea
      managementType
      pet
      floor
      room
      managementFee
      repairCost
      otherCost
      parking
      carsInParkingN
      parkingTakeOver
      parkingTakeOverFree
      parkingMechOrPlane
      parkingHeight
      parkingFee
      purposeB
      purposeSummaryB
      companyId
      companyName
      companyAddress
      companyTel
      companyFax
      companyUrl
      companyLicense
      companyGuaranter
      subCompanyId
      subCompanyName
      subCompanyAddress
      subCompanyTel
      subCompanyFax
      subCompanyUrl
      subCompanyLicense
      subCompanyGuaranter
      subCompany2Id
      subCompany2Name
      subCompany2Address
      subCompany2Tel
      subCompany2Fax
      subCompany2Url
      subCompany2License
      subCompany2Guaranter
      latitude
      longitude
      tileOnZoom12
      tileOnZoom14
      tileOnZoom16
      layoutImgs
      appearanceImgs
      mapImg
      waterPdf
      sewerPdf
      gasPdf
      cadastralMapPdf
      groundPdf
      url
      license
      recNo
      media
      location
      collectedAt
      floorStructure
      buildingCompany
      notFoundAt
      exactLocation
      nearlyLocation
      unsettledLocation
      isLot
      currentStatusB
      currentStatusG
      companyRecNo
      companyBranchId
      subCompanyRecNo
      completionMonthS
      condominiumName
      condominiumRecNo
      totalHouses
      manager
      seller
      estateUrl
      searchInfo
      owner
      createdAt
      updatedAt
      sold
      __typename
    }
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ConvertEstateGroundToLotFMutationVariables,
  APITypes.ConvertEstateGroundToLotFMutation
>;
export const convertEstateLotToGroundF = /* GraphQL */ `mutation ConvertEstateLotToGroundF($input: DeleteEstateInput!) {
  convertEstateLotToGroundF(input: $input) {
    item {
      id
      infoName
      condominiumId
      confirmed
      status
      estateType
      name
      fullAddress
      prefecture
      city
      area
      address
      mediationKind
      currentStatus
      currentStatusFree
      traffic
      eSchool
      jSchool
      remarks
      landAreaType
      squareArea
      tsuboArea
      estatePrice
      unitPrice
      pricedAt
      priceHistory {
        price
        pricedAt
        __typename
      }
      completionSchMonth
      completionMonth
      clearingType
      cleaningMonth
      bldConditionType
      landRights
      cityPlanningType
      useDistrict
      floorAreaRatio
      bcr
      roadType
      drivewayPay
      roadDirection
      roadWidth
      water
      sewer
      gas
      groundType
      groundTypeFree
      layout
      totalArea
      structure
      structureFree
      buildingFloors
      balconyArea
      managementType
      pet
      floor
      room
      managementFee
      repairCost
      otherCost
      parking
      carsInParkingN
      parkingTakeOver
      parkingTakeOverFree
      parkingMechOrPlane
      parkingHeight
      parkingFee
      purposeB
      purposeSummaryB
      companyId
      companyName
      companyAddress
      companyTel
      companyFax
      companyUrl
      companyLicense
      companyGuaranter
      subCompanyId
      subCompanyName
      subCompanyAddress
      subCompanyTel
      subCompanyFax
      subCompanyUrl
      subCompanyLicense
      subCompanyGuaranter
      subCompany2Id
      subCompany2Name
      subCompany2Address
      subCompany2Tel
      subCompany2Fax
      subCompany2Url
      subCompany2License
      subCompany2Guaranter
      latitude
      longitude
      tileOnZoom12
      tileOnZoom14
      tileOnZoom16
      layoutImgs
      appearanceImgs
      mapImg
      waterPdf
      sewerPdf
      gasPdf
      cadastralMapPdf
      groundPdf
      url
      license
      recNo
      media
      location
      collectedAt
      floorStructure
      buildingCompany
      notFoundAt
      exactLocation
      nearlyLocation
      unsettledLocation
      isLot
      currentStatusB
      currentStatusG
      companyRecNo
      companyBranchId
      subCompanyRecNo
      completionMonthS
      condominiumName
      condominiumRecNo
      totalHouses
      manager
      seller
      estateUrl
      searchInfo
      owner
      createdAt
      updatedAt
      sold
      __typename
    }
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ConvertEstateLotToGroundFMutationVariables,
  APITypes.ConvertEstateLotToGroundFMutation
>;
export const notifyEstateC = /* GraphQL */ `mutation NotifyEstateC($input: NotifyEstateInput!) {
  notifyEstateC(input: $input) {
    id
    infoName
    condominiumId
    confirmed
    status
    estateType
    name
    fullAddress
    prefecture
    city
    area
    address
    mediationKind
    currentStatus
    currentStatusFree
    traffic
    eSchool
    jSchool
    remarks
    landAreaType
    squareArea
    tsuboArea
    estatePrice
    unitPrice
    pricedAt
    priceHistory {
      price
      pricedAt
      __typename
    }
    completionSchMonth
    completionMonth
    clearingType
    cleaningMonth
    bldConditionType
    landRights
    cityPlanningType
    useDistrict
    floorAreaRatio
    bcr
    roadType
    drivewayPay
    roadDirection
    roadWidth
    water
    sewer
    gas
    groundType
    groundTypeFree
    layout
    totalArea
    structure
    structureFree
    buildingFloors
    balconyArea
    managementType
    pet
    floor
    room
    managementFee
    repairCost
    otherCost
    parking
    carsInParkingN
    parkingTakeOver
    parkingTakeOverFree
    parkingMechOrPlane
    parkingHeight
    parkingFee
    purposeB
    purposeSummaryB
    companyId
    companyName
    companyAddress
    companyTel
    companyFax
    companyUrl
    companyLicense
    companyGuaranter
    subCompanyId
    subCompanyName
    subCompanyAddress
    subCompanyTel
    subCompanyFax
    subCompanyUrl
    subCompanyLicense
    subCompanyGuaranter
    subCompany2Id
    subCompany2Name
    subCompany2Address
    subCompany2Tel
    subCompany2Fax
    subCompany2Url
    subCompany2License
    subCompany2Guaranter
    latitude
    longitude
    tileOnZoom12
    tileOnZoom14
    tileOnZoom16
    layoutImgs
    appearanceImgs
    mapImg
    waterPdf
    sewerPdf
    gasPdf
    cadastralMapPdf
    groundPdf
    url
    license
    recNo
    media
    location
    collectedAt
    floorStructure
    buildingCompany
    notFoundAt
    exactLocation
    nearlyLocation
    unsettledLocation
    isLot
    currentStatusB
    currentStatusG
    companyRecNo
    companyBranchId
    subCompanyRecNo
    completionMonthS
    condominiumName
    condominiumRecNo
    totalHouses
    manager
    seller
    estateUrl
    searchInfo
    owner
    createdAt
    updatedAt
    sold
    __typename
  }
}
` as GeneratedMutation<
  APITypes.NotifyEstateCMutationVariables,
  APITypes.NotifyEstateCMutation
>;
export const createStickyNoteF = /* GraphQL */ `mutation CreateStickyNoteF($input: CreateStickyNoteInput!) {
  createStickyNoteF(input: $input) {
    item {
      id
      infoName
      memoKind
      openLevel
      icon
      color
      description
      companyId
      latitude
      longitude
      tileOnZoom12
      tileOnZoom14
      tileOnZoom16
      searchInfo
      owner
      createdAt
      updatedAt
      __typename
    }
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateStickyNoteFMutationVariables,
  APITypes.CreateStickyNoteFMutation
>;
export const updateStickyNoteF = /* GraphQL */ `mutation UpdateStickyNoteF($input: UpdateStickyNoteInput!) {
  updateStickyNoteF(input: $input) {
    item {
      id
      infoName
      memoKind
      openLevel
      icon
      color
      description
      companyId
      latitude
      longitude
      tileOnZoom12
      tileOnZoom14
      tileOnZoom16
      searchInfo
      owner
      createdAt
      updatedAt
      __typename
    }
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateStickyNoteFMutationVariables,
  APITypes.UpdateStickyNoteFMutation
>;
export const deleteStickyNoteF = /* GraphQL */ `mutation DeleteStickyNoteF($input: DeleteStickyNoteInput!) {
  deleteStickyNoteF(input: $input) {
    item {
      id
      infoName
      memoKind
      openLevel
      icon
      color
      description
      companyId
      latitude
      longitude
      tileOnZoom12
      tileOnZoom14
      tileOnZoom16
      searchInfo
      owner
      createdAt
      updatedAt
      __typename
    }
    errorMessage
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteStickyNoteFMutationVariables,
  APITypes.DeleteStickyNoteFMutation
>;
export const invokeExportUserCsv = /* GraphQL */ `mutation InvokeExportUserCsv($todayOnly: Boolean) {
  invokeExportUserCsv(todayOnly: $todayOnly)
}
` as GeneratedMutation<
  APITypes.InvokeExportUserCsvMutationVariables,
  APITypes.InvokeExportUserCsvMutation
>;
export const createPublicEstateF = /* GraphQL */ `mutation CreatePublicEstateF($input: CreatePublicEstateInput!) {
  createPublicEstateF(input: $input) {
    id
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePublicEstateFMutationVariables,
  APITypes.CreatePublicEstateFMutation
>;
export const createPublicEstatePdfF = /* GraphQL */ `mutation CreatePublicEstatePdfF($input: CreatePublicEstatePdfInput!) {
  createPublicEstatePdfF(input: $input) {
    pdf
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePublicEstatePdfFMutationVariables,
  APITypes.CreatePublicEstatePdfFMutation
>;
export const createAnnounce = /* GraphQL */ `mutation CreateAnnounce(
  $input: CreateAnnounceInput!
  $condition: ModelAnnounceConditionInput
) {
  createAnnounce(input: $input, condition: $condition) {
    id
    level
    subject
    body
    ym
    owner
    createdAt
    updatedAt
    expiredAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAnnounceMutationVariables,
  APITypes.CreateAnnounceMutation
>;
export const updateAnnounce = /* GraphQL */ `mutation UpdateAnnounce(
  $input: UpdateAnnounceInput!
  $condition: ModelAnnounceConditionInput
) {
  updateAnnounce(input: $input, condition: $condition) {
    id
    level
    subject
    body
    ym
    owner
    createdAt
    updatedAt
    expiredAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAnnounceMutationVariables,
  APITypes.UpdateAnnounceMutation
>;
export const deleteAnnounce = /* GraphQL */ `mutation DeleteAnnounce(
  $input: DeleteAnnounceInput!
  $condition: ModelAnnounceConditionInput
) {
  deleteAnnounce(input: $input, condition: $condition) {
    id
    level
    subject
    body
    ym
    owner
    createdAt
    updatedAt
    expiredAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAnnounceMutationVariables,
  APITypes.DeleteAnnounceMutation
>;
export const createBatchJob = /* GraphQL */ `mutation CreateBatchJob(
  $input: CreateBatchJobInput!
  $condition: ModelBatchJobConditionInput
) {
  createBatchJob(input: $input, condition: $condition) {
    id
    jobName
    remarks
    status
    createdAt
    updatedAt
    expiredAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBatchJobMutationVariables,
  APITypes.CreateBatchJobMutation
>;
export const createBatchJobConf = /* GraphQL */ `mutation CreateBatchJobConf(
  $input: CreateBatchJobConfInput!
  $condition: ModelBatchJobConfConditionInput
) {
  createBatchJobConf(input: $input, condition: $condition) {
    jobName
    remarks
    allowedGroups
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBatchJobConfMutationVariables,
  APITypes.CreateBatchJobConfMutation
>;
export const updateBatchJobConf = /* GraphQL */ `mutation UpdateBatchJobConf(
  $input: UpdateBatchJobConfInput!
  $condition: ModelBatchJobConfConditionInput
) {
  updateBatchJobConf(input: $input, condition: $condition) {
    jobName
    remarks
    allowedGroups
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBatchJobConfMutationVariables,
  APITypes.UpdateBatchJobConfMutation
>;
export const deleteBatchJobConf = /* GraphQL */ `mutation DeleteBatchJobConf(
  $input: DeleteBatchJobConfInput!
  $condition: ModelBatchJobConfConditionInput
) {
  deleteBatchJobConf(input: $input, condition: $condition) {
    jobName
    remarks
    allowedGroups
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBatchJobConfMutationVariables,
  APITypes.DeleteBatchJobConfMutation
>;
export const createReport = /* GraphQL */ `mutation CreateReport(
  $input: CreateReportInput!
  $condition: ModelReportConditionInput
) {
  createReport(input: $input, condition: $condition) {
    id
    estateId
    estateName
    reportKind
    description
    latitude
    longitude
    companyId
    companyName
    userName
    owner
    createdAt
    updatedAt
    expiredAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateReportMutationVariables,
  APITypes.CreateReportMutation
>;
export const updateReport = /* GraphQL */ `mutation UpdateReport(
  $input: UpdateReportInput!
  $condition: ModelReportConditionInput
) {
  updateReport(input: $input, condition: $condition) {
    id
    estateId
    estateName
    reportKind
    description
    latitude
    longitude
    companyId
    companyName
    userName
    owner
    createdAt
    updatedAt
    expiredAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateReportMutationVariables,
  APITypes.UpdateReportMutation
>;
export const deleteReport = /* GraphQL */ `mutation DeleteReport(
  $input: DeleteReportInput!
  $condition: ModelReportConditionInput
) {
  deleteReport(input: $input, condition: $condition) {
    id
    estateId
    estateName
    reportKind
    description
    latitude
    longitude
    companyId
    companyName
    userName
    owner
    createdAt
    updatedAt
    expiredAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteReportMutationVariables,
  APITypes.DeleteReportMutation
>;
export const createScraping = /* GraphQL */ `mutation CreateScraping(
  $input: CreateScrapingInput!
  $condition: ModelScrapingConditionInput
) {
  createScraping(input: $input, condition: $condition) {
    baseUrl
    prefName
    status
    operation
    input
    startedAt
    totalCount
    updating {
      newRecord
      updatePrice
      expired
      return
      returnAndUpdatePrice
      scrapingFailed
      requiresAuth
      blocked
      updateFailed
      __typename
    }
    updated {
      newRecord
      updatePrice
      expired
      return
      returnAndUpdatePrice
      scrapingFailed
      requiresAuth
      blocked
      updateFailed
      __typename
    }
    resultKey
    endedAt
    output
    remarks
    sortKey
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateScrapingMutationVariables,
  APITypes.CreateScrapingMutation
>;
export const updateScraping = /* GraphQL */ `mutation UpdateScraping(
  $input: UpdateScrapingInput!
  $condition: ModelScrapingConditionInput
) {
  updateScraping(input: $input, condition: $condition) {
    baseUrl
    prefName
    status
    operation
    input
    startedAt
    totalCount
    updating {
      newRecord
      updatePrice
      expired
      return
      returnAndUpdatePrice
      scrapingFailed
      requiresAuth
      blocked
      updateFailed
      __typename
    }
    updated {
      newRecord
      updatePrice
      expired
      return
      returnAndUpdatePrice
      scrapingFailed
      requiresAuth
      blocked
      updateFailed
      __typename
    }
    resultKey
    endedAt
    output
    remarks
    sortKey
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateScrapingMutationVariables,
  APITypes.UpdateScrapingMutation
>;
