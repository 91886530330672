import { onAutocompleteChange } from "common/interfaces/AutocompleteHandlers";
import { City } from "common/interfaces/City";
import { Prefecture } from "common/interfaces/Prefecture";
import { MainContextContainer } from "components/Main";
import { useCityHooks } from "hooks/cityHooks";
import {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { specialCities } from "./const";
import { SwitchAreaDialogProps } from "./interfaces";

export const useSwitchAreaDialogHooks = (props: SwitchAreaDialogProps) => {
  const initRef = useRef(true);

  const titRef = useRef<HTMLDivElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);
  const listboxRef = useRef<Element>(null);

  const mainContext = useContext(MainContextContainer);

  const [selectedPrefectures, setSelectedPrefectures] = useState<Prefecture[]>(
    []
  );
  const [selectedCities, setSelectedCities] = useState<City[]>([]);

  const [prefecturesErrorMessage, setPrefecturesErrorMessage] = useState<
    string | null
  >("");
  const [citiesErrorMessage, setCitiesErrorMessage] = useState("");

  const { cities } = useCityHooks(selectedPrefectures, mainContext.setFatal);

  const defaultPrefectures = useMemo(
    () =>
      [
        ...new Set(
          [
            ...(mainContext.company?.areas ?? []),
            ...(mainContext.company?.areaPrefs ?? [])
          ].map((area) => area?.substring(0, 2))
        )
      ]
        ?.map((pref_code) =>
          cities.find((city) => city.pref_code === pref_code)
        )
        .filter((pref) => pref !== undefined) as Prefecture[],
    [cities, mainContext.company?.areaPrefs, mainContext.company?.areas]
  );

  const defaultCities = useMemo(
    () =>
      mainContext.company?.areas
        ?.map((city_code) =>
          cities.find((city) => city.city_code === city_code)
        )
        .filter((city) => city !== undefined) as City[],
    [cities, mainContext.company?.areas]
  );

  const defaultSelectableCities = useMemo(
    () =>
      mainContext.company?.eAreas
        ?.map((city_code) =>
          cities.find((city) => city.city_code === city_code)
        )
        .filter((city) => city !== undefined) as City[],
    [cities, mainContext.company?.eAreas]
  );

  const selectableCities = useMemo(
    () =>
      ((defaultSelectableCities?.length ?? 0) !== 0
        ? defaultSelectableCities
        : cities.filter((city) => {
            const specialCityCodes = specialCities
              .filter((specialCity) => specialCity.pref_code === city.pref_code)
              .map((x) => x.city_code);

            return (
              specialCityCodes.includes(city.city_code) ||
              specialCityCodes.length === 0
            );
          })
      ).map((city) => {
        const specialCity = specialCities.find(
          (x) => x.city_code === city.city_code
        );

        return {
          ...city,
          area_code: specialCity?.area_code,
          area_name: specialCity?.area_name
        };
      }),
    [cities, defaultSelectableCities]
  );

  const selectAllButtonOn = useMemo(
    () => selectedCities.length !== selectableCities.length,
    [selectableCities.length, selectedCities.length]
  );

  const selectMyAreaButtonOn = useMemo(
    () =>
      selectedCities.length !== (defaultCities ?? []).length ||
      !(defaultCities ?? [])
        .map((x) => `${x.pref_code}${x.city_code}`)
        .every((x) =>
          selectedCities.map((y) => `${y.pref_code}${y.city_code}`).includes(x)
        ),
    [defaultCities, selectedCities]
  );

  const okButtonOn = useMemo(
    () =>
      selectedCities.length !== 0 &&
      (props.selectedCities.length !== selectedCities.length ||
        !props.selectedCities
          .map((x) => `${x.pref_code}${x.city_code}`)
          .every((x) =>
            selectedCities
              .map((y) => `${y.pref_code}${y.city_code}`)
              .includes(x)
          )),
    [props.selectedCities, selectedCities]
  );

  const onPrefecturesChange: onAutocompleteChange<Prefecture | string> =
    useCallback(
      (_, value) => {
        if (Array.isArray(value)) {
          setSelectedPrefectures(value as Prefecture[]);

          if (
            !selectedCities.some((x) =>
              (value as Prefecture[])
                .map((prefecture) => prefecture.pref_code)
                .includes(x.pref_code)
            )
          ) {
            setSelectedCities([]);
          }
        }
      },
      [selectedCities]
    );

  const onCitiesClick = useCallback(
    (city: City) => {
      const newSelectedCities = [...selectedCities];
      const foundIndex = newSelectedCities.findIndex(
        (x) => x.city_code === city.city_code
      );

      if (foundIndex > -1) {
        newSelectedCities.splice(foundIndex, 1);
      } else {
        newSelectedCities.push(city);
      }

      setSelectedCities(newSelectedCities);
    },
    [selectedCities]
  );

  const onResetButtonClick = useCallback(() => {
    setSelectedPrefectures([...defaultPrefectures]);
    setSelectedCities([...defaultCities]);
  }, [defaultCities, defaultPrefectures]);

  const onAllAreaButtonClick = useCallback(() => {
    setSelectedCities([...selectableCities]);
  }, [selectableCities]);

  const onOkButtonClick = useCallback(() => {
    if (props.onOkButtonClick) {
      let executable = true;

      if (selectedPrefectures.length === 0) {
        setPrefecturesErrorMessage("都道府県を選択してください。");
        executable = false;
      } else {
        setPrefecturesErrorMessage("");
      }

      if (selectedCities.length === 0) {
        setCitiesErrorMessage("市区町村を選択してください。");
        executable = false;
      } else {
        setCitiesErrorMessage("");
      }

      if (executable) {
        props.onOkButtonClick(selectedPrefectures, selectedCities);
      }
    }
  }, [props, selectedCities, selectedPrefectures]);

  const onClose = useCallback(() => {
    if (props.onClose) {
      props.onClose();
    }
  }, [props]);

  const onBackPaperClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (
        (titRef.current !== null &&
          titRef.current.contains(event.target as Node)) ||
        (boxRef.current !== null &&
          boxRef.current.contains(event.target as Node)) ||
        (listboxRef.current !== null &&
          listboxRef.current.contains(event.target as Node))
      ) {
        return;
      }

      if (props.onClose) {
        props.onClose();
      }
    },
    [props]
  );

  useEffect(() => {
    if (initRef.current) {
      initRef.current = false;
    }
  }, []);

  useEffect(() => {
    setPrefecturesErrorMessage("");
    setCitiesErrorMessage("");
  }, [props.open]);

  useEffect(() => {
    if (props.open) {
      setSelectedPrefectures(props.selectedPrefectures);
    } else {
      setSelectedPrefectures([]);
    }
  }, [props.selectedPrefectures, props.open]);

  useEffect(() => {
    if (props.open && props.selectedPrefectures.length !== 0) {
      setSelectedCities(props.selectedCities);
    } else {
      setSelectedCities([]);
    }
  }, [props.open, props.selectedCities, props.selectedPrefectures.length]);

  return {
    titRef,
    boxRef,
    listboxRef,

    selectedPrefectures,
    selectedCities,
    prefecturesErrorMessage,
    citiesErrorMessage,
    selectableCities,

    selectAllButtonOn,
    selectMyAreaButtonOn,
    okButtonOn,

    onPrefecturesChange,
    onCitiesClick,
    onResetButtonClick,
    onAllAreaButtonClick,
    onOkButtonClick,
    onClose,
    onBackPaperClick
  } as const;
};
