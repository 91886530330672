export const specialCities = [
  // 広島県
  { pref_code: "34", city_code: "34211" }, // 大竹市
  { pref_code: "34", city_code: "34213" }, // 廿日市市

  // 福岡県
  { pref_code: "40", area_code: "01", area_name: "福岡市", city_code: "40131" }, // 福岡市東区
  { pref_code: "40", area_code: "01", area_name: "福岡市", city_code: "40132" }, // 福岡市博多区
  { pref_code: "40", area_code: "01", area_name: "福岡市", city_code: "40133" }, // 福岡市中央区
  { pref_code: "40", area_code: "01", area_name: "福岡市", city_code: "40134" }, // 福岡市南区
  { pref_code: "40", area_code: "01", area_name: "福岡市", city_code: "40135" }, // 福岡市西区
  { pref_code: "40", area_code: "01", area_name: "福岡市", city_code: "40136" }, // 福岡市城南区
  { pref_code: "40", area_code: "01", area_name: "福岡市", city_code: "40137" }, // 福岡市早良区
  {
    pref_code: "40",
    area_code: "02",
    area_name: "福岡地域",
    city_code: "40217"
  }, // 筑紫野市
  {
    pref_code: "40",
    area_code: "02",
    area_name: "福岡地域",
    city_code: "40218"
  }, // 春日市
  {
    pref_code: "40",
    area_code: "02",
    area_name: "福岡地域",
    city_code: "40219"
  }, // 大野城市
  {
    pref_code: "40",
    area_code: "02",
    area_name: "福岡地域",
    city_code: "40221"
  }, // 太宰府市
  {
    pref_code: "40",
    area_code: "02",
    area_name: "福岡地域",
    city_code: "40230"
  }, // 糸島市
  {
    pref_code: "40",
    area_code: "02",
    area_name: "福岡地域",
    city_code: "40231"
  }, // 那珂川市
  {
    pref_code: "40",
    area_code: "03",
    area_name: "糟屋・宗像地域",
    city_code: "40220"
  }, // 宗像市
  {
    pref_code: "40",
    area_code: "03",
    area_name: "糟屋・宗像地域",
    city_code: "40223"
  }, // 古賀市
  {
    pref_code: "40",
    area_code: "03",
    area_name: "糟屋・宗像地域",
    city_code: "40224"
  }, // 福津市
  {
    pref_code: "40",
    area_code: "03",
    area_name: "糟屋・宗像地域",
    city_code: "40341"
  }, // 糟屋郡宇美町
  {
    pref_code: "40",
    area_code: "03",
    area_name: "糟屋・宗像地域",
    city_code: "40342"
  }, // 糟屋郡篠栗町
  {
    pref_code: "40",
    area_code: "03",
    area_name: "糟屋・宗像地域",
    city_code: "40343"
  }, // 糟屋郡志免町
  {
    pref_code: "40",
    area_code: "03",
    area_name: "糟屋・宗像地域",
    city_code: "40344"
  }, // 糟屋郡須惠町
  {
    pref_code: "40",
    area_code: "03",
    area_name: "糟屋・宗像地域",
    city_code: "40345"
  }, // 糟屋郡新宮町
  {
    pref_code: "40",
    area_code: "03",
    area_name: "糟屋・宗像地域",
    city_code: "40348"
  }, // 糟屋郡久山町
  {
    pref_code: "40",
    area_code: "03",
    area_name: "糟屋・宗像地域",
    city_code: "40349"
  }, // 糟屋郡粕屋町
  {
    pref_code: "40",
    area_code: "04",
    area_name: "北筑後地域",
    city_code: "40203"
  }, // 久留米市
  {
    pref_code: "40",
    area_code: "04",
    area_name: "北筑後地域",
    city_code: "40216"
  }, // 小郡市
  {
    pref_code: "40",
    area_code: "04",
    area_name: "北筑後地域",
    city_code: "40225"
  }, // うきは市
  {
    pref_code: "40",
    area_code: "04",
    area_name: "北筑後地域",
    city_code: "40228"
  }, // 朝倉市
  {
    pref_code: "40",
    area_code: "04",
    area_name: "北筑後地域",
    city_code: "40447"
  }, // 朝倉郡筑前町
  {
    pref_code: "40",
    area_code: "04",
    area_name: "北筑後地域",
    city_code: "40448"
  }, // 朝倉郡東峰村
  {
    pref_code: "40",
    area_code: "04",
    area_name: "北筑後地域",
    city_code: "40503"
  }, // 三井郡大刀洗町
  {
    pref_code: "40",
    area_code: "05",
    area_name: "南筑後地域",
    city_code: "40202"
  }, // 大牟田市
  {
    pref_code: "40",
    area_code: "05",
    area_name: "南筑後地域",
    city_code: "40207"
  }, // 柳川市
  {
    pref_code: "40",
    area_code: "05",
    area_name: "南筑後地域",
    city_code: "40210"
  }, // 八女市
  {
    pref_code: "40",
    area_code: "05",
    area_name: "南筑後地域",
    city_code: "40211"
  }, // 筑後市
  {
    pref_code: "40",
    area_code: "05",
    area_name: "南筑後地域",
    city_code: "40212"
  }, // 大川市
  {
    pref_code: "40",
    area_code: "05",
    area_name: "南筑後地域",
    city_code: "40229"
  }, // みやま市
  {
    pref_code: "40",
    area_code: "05",
    area_name: "南筑後地域",
    city_code: "40522"
  }, // 三潴郡大木町
  {
    pref_code: "40",
    area_code: "05",
    area_name: "南筑後地域",
    city_code: "40544"
  }, // 八女郡広川町
  {
    pref_code: "40",
    area_code: "06",
    area_name: "北九州市",
    city_code: "40101"
  }, // 北九州市門司区
  {
    pref_code: "40",
    area_code: "06",
    area_name: "北九州市",
    city_code: "40103"
  }, // 北九州市若松区
  {
    pref_code: "40",
    area_code: "06",
    area_name: "北九州市",
    city_code: "40105"
  }, // 北九州市戸畑区
  {
    pref_code: "40",
    area_code: "06",
    area_name: "北九州市",
    city_code: "40106"
  }, // 北九州市小倉北区
  {
    pref_code: "40",
    area_code: "06",
    area_name: "北九州市",
    city_code: "40107"
  }, // 北九州市小倉南区
  {
    pref_code: "40",
    area_code: "06",
    area_name: "北九州市",
    city_code: "40108"
  }, // 北九州市八幡東区
  {
    pref_code: "40",
    area_code: "06",
    area_name: "北九州市",
    city_code: "40109"
  }, // 北九州市八幡西区
  {
    pref_code: "40",
    area_code: "07",
    area_name: "京築地域",
    city_code: "40213"
  }, // 行橋市
  {
    pref_code: "40",
    area_code: "07",
    area_name: "京築地域",
    city_code: "40214"
  }, // 豊前市
  {
    pref_code: "40",
    area_code: "07",
    area_name: "京築地域",
    city_code: "40621"
  }, // 京都郡苅田町
  {
    pref_code: "40",
    area_code: "07",
    area_name: "京築地域",
    city_code: "40625"
  }, // 京都郡みやこ町
  {
    pref_code: "40",
    area_code: "07",
    area_name: "京築地域",
    city_code: "40642"
  }, // 築上郡吉富町
  {
    pref_code: "40",
    area_code: "07",
    area_name: "京築地域",
    city_code: "40646"
  }, // 築上郡上毛町
  {
    pref_code: "40",
    area_code: "07",
    area_name: "京築地域",
    city_code: "40647"
  }, // 築上郡築上町
  {
    pref_code: "40",
    area_code: "08",
    area_name: "遠賀地域",
    city_code: "40215"
  }, // 中間市
  {
    pref_code: "40",
    area_code: "08",
    area_name: "遠賀地域",
    city_code: "40381"
  }, // 遠賀郡芦屋町
  {
    pref_code: "40",
    area_code: "08",
    area_name: "遠賀地域",
    city_code: "40382"
  }, // 遠賀郡水巻町
  {
    pref_code: "40",
    area_code: "08",
    area_name: "遠賀地域",
    city_code: "40383"
  }, // 遠賀郡岡垣町
  {
    pref_code: "40",
    area_code: "08",
    area_name: "遠賀地域",
    city_code: "40384"
  }, // 遠賀郡遠賀町
  {
    pref_code: "40",
    area_code: "09",
    area_name: "筑豊地域",
    city_code: "40204"
  }, // 直方市
  {
    pref_code: "40",
    area_code: "09",
    area_name: "筑豊地域",
    city_code: "40205"
  }, // 飯塚市
  {
    pref_code: "40",
    area_code: "09",
    area_name: "筑豊地域",
    city_code: "40206"
  }, // 田川市
  {
    pref_code: "40",
    area_code: "09",
    area_name: "筑豊地域",
    city_code: "40226"
  }, // 宮若市
  {
    pref_code: "40",
    area_code: "09",
    area_name: "筑豊地域",
    city_code: "40227"
  }, // 嘉麻市
  {
    pref_code: "40",
    area_code: "09",
    area_name: "筑豊地域",
    city_code: "40401"
  }, // 鞍手郡小竹町
  {
    pref_code: "40",
    area_code: "09",
    area_name: "筑豊地域",
    city_code: "40402"
  }, // 鞍手郡鞍手町
  {
    pref_code: "40",
    area_code: "09",
    area_name: "筑豊地域",
    city_code: "40421"
  }, // 嘉穂郡桂川町
  {
    pref_code: "40",
    area_code: "09",
    area_name: "筑豊地域",
    city_code: "40601"
  }, // 田川郡香春町
  {
    pref_code: "40",
    area_code: "09",
    area_name: "筑豊地域",
    city_code: "40602"
  }, // 田川郡添田町
  {
    pref_code: "40",
    area_code: "09",
    area_name: "筑豊地域",
    city_code: "40604"
  }, // 田川郡糸田町
  {
    pref_code: "40",
    area_code: "09",
    area_name: "筑豊地域",
    city_code: "40605"
  }, // 田川郡川崎町
  {
    pref_code: "40",
    area_code: "09",
    area_name: "筑豊地域",
    city_code: "40608"
  }, // 田川郡大任町
  {
    pref_code: "40",
    area_code: "09",
    area_name: "筑豊地域",
    city_code: "40609"
  }, // 田川郡赤村
  {
    pref_code: "40",
    area_code: "09",
    area_name: "筑豊地域",
    city_code: "40610"
  }, // 田川郡福智町

  // 熊本県
  { pref_code: "43", area_code: "1", area_name: "熊本市", city_code: "43101" }, // 熊本市中央区
  { pref_code: "43", area_code: "1", area_name: "熊本市", city_code: "43102" }, // 熊本市東区
  { pref_code: "43", area_code: "1", area_name: "熊本市", city_code: "43103" }, // 熊本市西区
  { pref_code: "43", area_code: "1", area_name: "熊本市", city_code: "43104" }, // 熊本市南区
  { pref_code: "43", area_code: "1", area_name: "熊本市", city_code: "43105" }, // 熊本市北区
  {
    pref_code: "43",
    area_code: "2",
    area_name: "菊池地域",
    city_code: "43210"
  }, // 菊池市
  {
    pref_code: "43",
    area_code: "2",
    area_name: "菊池地域",
    city_code: "43216"
  }, // 合志市
  {
    pref_code: "43",
    area_code: "2",
    area_name: "菊池地域",
    city_code: "43403"
  }, // 菊池郡大津町
  {
    pref_code: "43",
    area_code: "2",
    area_name: "菊池地域",
    city_code: "43404"
  }, // 菊池郡菊陽町
  {
    pref_code: "43",
    area_code: "3",
    area_name: "玉名・鹿本地域",
    city_code: "43204"
  }, // 荒尾市
  {
    pref_code: "43",
    area_code: "3",
    area_name: "玉名・鹿本地域",
    city_code: "43206"
  }, // 玉名市
  {
    pref_code: "43",
    area_code: "3",
    area_name: "玉名・鹿本地域",
    city_code: "43208"
  }, // 山鹿市
  {
    pref_code: "43",
    area_code: "3",
    area_name: "玉名・鹿本地域",
    city_code: "43364"
  }, // 玉名郡玉東町
  {
    pref_code: "43",
    area_code: "3",
    area_name: "玉名・鹿本地域",
    city_code: "43367"
  }, // 玉名郡南関町
  {
    pref_code: "43",
    area_code: "3",
    area_name: "玉名・鹿本地域",
    city_code: "43368"
  }, // 玉名郡長洲町
  {
    pref_code: "43",
    area_code: "3",
    area_name: "玉名・鹿本地域",
    city_code: "43369"
  }, // 玉名郡和水町
  {
    pref_code: "43",
    area_code: "4",
    area_name: "阿蘇地域",
    city_code: "43214"
  }, // 阿蘇市
  {
    pref_code: "43",
    area_code: "4",
    area_name: "阿蘇地域",
    city_code: "43423"
  }, // 阿蘇郡南小国町
  {
    pref_code: "43",
    area_code: "4",
    area_name: "阿蘇地域",
    city_code: "43424"
  }, // 阿蘇郡小国町
  {
    pref_code: "43",
    area_code: "4",
    area_name: "阿蘇地域",
    city_code: "43425"
  }, // 阿蘇郡産山村
  {
    pref_code: "43",
    area_code: "4",
    area_name: "阿蘇地域",
    city_code: "43428"
  }, // 阿蘇郡高森町
  {
    pref_code: "43",
    area_code: "4",
    area_name: "阿蘇地域",
    city_code: "43432"
  }, // 阿蘇郡西原村
  {
    pref_code: "43",
    area_code: "4",
    area_name: "阿蘇地域",
    city_code: "43433"
  }, // 阿蘇郡南阿蘇村
  {
    pref_code: "43",
    area_code: "5",
    area_name: "上益城地域",
    city_code: "43441"
  }, // 上益城郡御船町
  {
    pref_code: "43",
    area_code: "5",
    area_name: "上益城地域",
    city_code: "43442"
  }, // 上益城郡嘉島町
  {
    pref_code: "43",
    area_code: "5",
    area_name: "上益城地域",
    city_code: "43443"
  }, // 上益城郡益城町
  {
    pref_code: "43",
    area_code: "5",
    area_name: "上益城地域",
    city_code: "43444"
  }, // 上益城郡甲佐町
  {
    pref_code: "43",
    area_code: "5",
    area_name: "上益城地域",
    city_code: "43447"
  }, // 上益城郡山都町
  {
    pref_code: "43",
    area_code: "6",
    area_name: "宇城・八代地域",
    city_code: "43211"
  }, // 宇土市
  {
    pref_code: "43",
    area_code: "6",
    area_name: "宇城・八代地域",
    city_code: "43213"
  }, // 宇城市
  {
    pref_code: "43",
    area_code: "6",
    area_name: "宇城・八代地域",
    city_code: "43202"
  }, // 八代市
  {
    pref_code: "43",
    area_code: "6",
    area_name: "宇城・八代地域",
    city_code: "43348"
  }, // 下益城郡美里町
  {
    pref_code: "43",
    area_code: "6",
    area_name: "宇城・八代地域",
    city_code: "43468"
  }, // 八代郡氷川町
  {
    pref_code: "43",
    area_code: "7",
    area_name: "天草地域",
    city_code: "43212"
  }, // 上天草市
  {
    pref_code: "43",
    area_code: "7",
    area_name: "天草地域",
    city_code: "43215"
  }, // 天草市
  {
    pref_code: "43",
    area_code: "7",
    area_name: "天草地域",
    city_code: "43531"
  }, // 天草郡苓北町
  {
    pref_code: "43",
    area_code: "8",
    area_name: "球磨地域",
    city_code: "43203"
  }, // 人吉市
  {
    pref_code: "43",
    area_code: "8",
    area_name: "球磨地域",
    city_code: "43501"
  }, // 球磨郡錦町
  {
    pref_code: "43",
    area_code: "8",
    area_name: "球磨地域",
    city_code: "43505"
  }, // 球磨郡多良木町
  {
    pref_code: "43",
    area_code: "8",
    area_name: "球磨地域",
    city_code: "43506"
  }, // 球磨郡湯前町
  {
    pref_code: "43",
    area_code: "8",
    area_name: "球磨地域",
    city_code: "43507"
  }, // 球磨郡水上村
  {
    pref_code: "43",
    area_code: "8",
    area_name: "球磨地域",
    city_code: "43510"
  }, // 球磨郡相良村
  {
    pref_code: "43",
    area_code: "8",
    area_name: "球磨地域",
    city_code: "43511"
  }, // 球磨郡五木村
  {
    pref_code: "43",
    area_code: "8",
    area_name: "球磨地域",
    city_code: "43512"
  }, // 球磨郡山江村
  {
    pref_code: "43",
    area_code: "8",
    area_name: "球磨地域",
    city_code: "43513"
  }, // 球磨郡球磨村
  {
    pref_code: "43",
    area_code: "8",
    area_name: "球磨地域",
    city_code: "43514"
  }, // 球磨郡あさぎり町
  {
    pref_code: "43",
    area_code: "9",
    area_name: "芦北地域",
    city_code: "43205"
  }, // 水俣市
  {
    pref_code: "43",
    area_code: "9",
    area_name: "芦北地域",
    city_code: "43482"
  }, // 葦北郡芦北町
  { pref_code: "43", area_code: "9", area_name: "芦北地域", city_code: "43484" } // 葦北郡津奈木町
];
